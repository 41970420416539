import { Children } from "react";

import Grid from "../base/grid";
import AccentContainer from "../base/accent-container";
import Banner from "../base/banner";
import Image from "../base/image";
import { createClassName } from "../../../helpers";
import "./SuccessStory.scss";

const cn = createClassName("SuccessStory");

const SuccessStoryImage = ({ src, alt, viewbox = true }) => (
  <div
    className={cn("image-container", {
      viewbox: !!viewbox && viewbox !== "false",
    })}
  >
    <Image src={src} alt={alt} />
  </div>
);

const SuccessStory = ({ children }) => {
  const childrenArray = Children.toArray(children);
  const image = childrenArray.find(({ type }) => type === SuccessStoryImage);

  return (
    <Grid.Column size="lg">
      <AccentContainer className={cn("root")} variant="primary" padding="lg">
        <Banner>
          <Banner.Image>{image}</Banner.Image>
          {children}
        </Banner>
      </AccentContainer>
    </Grid.Column>
  );
};

SuccessStory.Image = SuccessStoryImage;
SuccessStory.Title = Banner.Title;
SuccessStory.Text = Banner.Content;

export default SuccessStory;
