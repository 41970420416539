import Grid from "../base/grid";
import TextColumn from "../base/text-column";

import "./OurServiceCard.scss";
import { createClassName } from "../../../helpers";

const cn = createClassName("OurServiceCard");

const OurServiceCard = ({ children, title }) => (
  <Grid.Column size="sm">
    <TextColumn className={cn("root")} variant="secondary" size="sm">
      <TextColumn.Title>{title}</TextColumn.Title>
      <TextColumn.Text>{children}</TextColumn.Text>
    </TextColumn>
  </Grid.Column>
);

export default OurServiceCard;
