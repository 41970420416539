import classNames from "classnames";

import { createClassName } from "../../../../helpers";

import "./AccentContainer.scss";

const cn = createClassName("AccentContainer");

const AccentContainer = ({ children, variant, padding = "md", className }) => (
  <div className={classNames(cn("container"), className)}>
    <div className={cn("root", { padding, variant })}>{children}</div>
  </div>
);

export default AccentContainer;
