import { createContext, useContext } from "react";

const imagesContext = createContext({ images: [] });

const ImagesProvider = ({ pageImages, children }) => (
  <imagesContext.Provider value={pageImages}>{children}</imagesContext.Provider>
);

export const useImagesContext = () => useContext(imagesContext);

export default ImagesProvider;
