import { Children } from "react";
import classNames from "classnames";

import { createClassName } from "../../../../helpers";
import Image from "../image";
import TextColumn from "../text-column";

import "./Banner.scss";

const cn = createClassName("Banner");

const BannerTag = ({ children }) => (
  <div className={cn("tag-container")}>
    <span className={cn("tag")}>{children}</span>
  </div>
);

const BannerTitle = TextColumn.Title;

const BannerContent = TextColumn.Text;

const BannerImage = ({ src, alt, children, fit }) => (
  <div className={cn("image-container")}>
    {children || <Image src={src} alt={alt} objectFit={fit} />}
  </div>
);

const Banner = ({ children, className }) => {
  const childrenArray = Children.toArray(children);

  const title = childrenArray.find(({ type }) => type === BannerTitle);
  const text = childrenArray.find(({ type }) => type === BannerContent);
  const tag = childrenArray.find(({ type }) => type === BannerTag);
  const image = childrenArray.find(({ type }) => type === BannerImage);

  return (
    <div className={classNames(cn("container"), className)}>
      <div className={cn("root", { withTag: !!tag })}>
        {tag}
        <div className={cn("content-container")}>
          <TextColumn>
            {title}
            {text}
          </TextColumn>
        </div>
        {image}
      </div>
    </div>
  );
};

Banner.Tag = BannerTag;
Banner.Title = BannerTitle;
Banner.Content = BannerContent;
Banner.Image = BannerImage;

export default Banner;
