import { useImagesContext } from "../images-provider";
import { imageManager } from "../../../Image";

const Image = ({ src, alt, ...props }) => {
  const images = useImagesContext();

  const ImageComponent = imageManager(images);

  return <ImageComponent src={src} alt={alt} {...props} />;
};

export default Image;
