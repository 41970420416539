import Section from "../base/section";
import Cta from "../base/cta";

import "./SectionCta.scss";
import { createClassName } from "../../../helpers";

const cn = createClassName("SectionCta");

const SectionCta = ({ children, width }) => (
  <Section variant="cta" width={width}>
    <Cta className={cn("cta")} size="lg">
      {children}
    </Cta>
  </Section>
);

export default SectionCta;
