/* eslint-disable react/prop-types */
import classNames from "classnames";

import { createClassName } from "../../../../helpers";

import "./Grid.scss";

const cn = createClassName("Grid");

const GridColumn = ({ children, size = "sm", collapsed = size, className }) => (
  <div className={classNames(cn("column", { collapsed, size }), className)}>
    {children}
  </div>
);

const Grid = ({ children, className }) => (
  <div className={classNames(cn("container"), className)}>
    <div className={cn("root")}>{children}</div>
  </div>
);

Grid.Column = GridColumn;

export default Grid;
