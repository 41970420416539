import { Children } from "react";
import classNames from "classnames";

import { createClassName } from "../../../../helpers";

import "./TextColumn.scss";

const cn = createClassName("TextColumn");

const TextColumnTitle = ({ children }) => (
  <span className={cn("title")}>{children}</span>
);

const TextColumnText = ({ children }) => (
  <div className={cn("text")}>{children}</div>
);

const TextColumnIcon = ({ children }) => (
  <div className={cn("icon-container")}>
    <div className={cn("icon")}>{children}</div>
  </div>
);

const TextColumn = ({
  children,
  size = "md",
  variant = "primary",
  align = "normal",
  className,
}) => {
  const childrenArray = Children.toArray(children);

  const icon = childrenArray.find(({ type }) => type === TextColumnIcon);
  const title = childrenArray.find(({ type }) => type === TextColumnTitle);
  const text = childrenArray.find(({ type }) => type === TextColumnText);

  return (
    <div
      className={classNames(
        cn("root", { size, variant, withIcon: !!icon, align }),
        className
      )}
    >
      {icon}
      {title}
      {text}
    </div>
  );
};

TextColumn.Text = TextColumnText;
TextColumn.Title = TextColumnTitle;
TextColumn.Icon = TextColumnIcon;

export default TextColumn;
