import AccentContainer from "../base/accent-container";
import TextColumn from "../base/text-column";

const OutstandingService = ({ children, title }) => (
  <AccentContainer variant="secondary">
    <TextColumn variant="secondary">
      <TextColumn.Title>{title}</TextColumn.Title>
      <TextColumn.Text>{children}</TextColumn.Text>
    </TextColumn>
  </AccentContainer>
);

export default OutstandingService;
