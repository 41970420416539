import AccentContainer from "../base/accent-container";
import Banner from "../base/banner";

import "./MainBanner.scss";
import { createClassName } from "../../../helpers";

const cn = createClassName("MainBanner");

const MainBanner = ({ children }) => (
  <AccentContainer variant="primary">
    <Banner className={cn("root")}>{children}</Banner>
  </AccentContainer>
);

export default MainBanner;
