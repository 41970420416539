import Grid from "../base/grid";
import AccentContainer from "../base/accent-container";
import Cta from "../base/cta";
import { createClassName } from "../../../helpers";

import "./Step.scss";

const cn = createClassName("Step");

const CtaStep = ({ children }) => (
  <Grid.Column className={cn("container")} size="sm">
    <AccentContainer className={cn("root")} variant="cta">
      <Cta variant="inverted" size="md">
        {children}
      </Cta>
    </AccentContainer>
  </Grid.Column>
);

export default CtaStep;
