import { createClassName } from "../../../helpers";
import { faqDetail } from "../../ForMarkDownComponents";

import "./Faq.scss";

const cn = createClassName("Faq");

const FaqItem = faqDetail;

const Faq = ({ children }) => <div className={cn("root")}>{children}</div>;

Faq.Item = FaqItem;

export default Faq;
