import classNames from "classnames";

import { createClassName } from "../../../../helpers";

import "./Section.scss";

const cn = createClassName("Section");

const Section = ({ children, title, subtitle, variant, width, className }) => (
  <div className={classNames(cn(), cn("container", { width }), className)}>
    <div className={cn("root", { variant })}>
      {(!variant || variant !== "cta") && (
        <div className={cn("title-container")}>
          <div className={cn("title")}>{title}</div>
          {!!subtitle && <div className={cn("subtitle")}>{subtitle}</div>}
        </div>
      )}
      <div className={cn("content")}>{children}</div>
    </div>
  </div>
);

export default Section;
