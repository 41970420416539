import { Children } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { createClassName } from "../../../../helpers";
import TextColumn from "../text-column";
import Link from "../../../Links/LinkOnPage";
import Icon from "../icon";

import "./Cta.scss";

const cn = createClassName("Cta");

const CtaTitle = TextColumn.Title;

const CtaText = TextColumn.Text;

const CtaAction = ({ children, href, icon }) => {
  const withoutLocale = href.startsWith("/go/");

  return (
    <div className={cn("action-container")}>
      <Link
        className={cn("action")}
        size="medium"
        isVolume
        color="white"
        href={href}
        withoutLocale={withoutLocale}
      >
        {icon ? (
          <div className={cn("action-icon-container")}>
            <span>{children}</span>
            <Icon icon={icon} />
          </div>
        ) : (
          children
        )}
      </Link>
    </div>
      );
};

const Cta = ({ children, size = "md", align, className }) => {
  const childrenArray = Children.toArray(children);

  const actionChild = childrenArray.find(({ type }) => type === CtaAction);

  return (
    <div className={classNames(cn("container", { align }), className)}>
      <div className={cn("root")}>
        <TextColumn
          className={cn("text-container")}
          variant="inverted"
          size={size}
          align={align}
        >
          {children}
        </TextColumn>
        {actionChild}
      </div>
    </div>
  );
};

Cta.Title = CtaTitle;
Cta.Text = CtaText;
Cta.Action = CtaAction;

CtaAction.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default Cta;
