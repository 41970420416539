import Icon from "../icon";
import LinkOnResource from "../../../Links/LinkOnResource";
import { createClassName } from "../../../../helpers";

import "./ExternalLink.scss";

const cn = createClassName("ExternalLink");

const ExternalLink = ({ children, href }) => (
  <LinkOnResource href={href} color="primary" isTarget withoutLocale>
    <div className={cn("icon-container")}>
      <span>{children}</span>
      <Icon icon="external-link-arrow" />
    </div>
  </LinkOnResource>
);

export default ExternalLink;
