import TextColumn from "../base/text-column";
import Grid from "../base/grid";
import { createClassName } from "../../../helpers";

import "./Advantage.scss";

const cn = createClassName("Advantage");

const Advantage = ({ children }) => (
  <Grid.Column size="sm" collapsed="md">
    <TextColumn className={cn()} variant="secondary">
      {children}
    </TextColumn>
  </Grid.Column>
);

export default Advantage;
