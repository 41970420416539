import Grid from "../base/grid";
import TextColumn from "../base/text-column";
import AccentContainer from "../base/accent-container";
import { createClassName } from "../../../helpers";

import "./AddonCreateStep.scss";

const cn = createClassName("AddonCreateStep");

const AddonCreateStep = ({ children, title, step }) => (
  <Grid.Column className={cn("container")} size="sm">
    <AccentContainer className={cn("root")} variant="secondary">
      <TextColumn variant="secondary" size="md">
        <TextColumn.Title>{title}</TextColumn.Title>
        <TextColumn.Text>{children}</TextColumn.Text>
      </TextColumn>
      <div className={cn("step")}>{step}</div>
    </AccentContainer>
  </Grid.Column>
);

export default AddonCreateStep;
