import Section from "../base/section";
import Cta from "../base/cta";
import { createClassName } from "../../../helpers";

import "./HeroSection.scss";

const cn = createClassName("HeroSection");

const HeroSection = ({ children }) => (
  <Section className={cn("root")} variant="cta" width="full">
    <Cta className={cn()} align="center">
      {children}
    </Cta>
  </Section>
);

export default HeroSection;
