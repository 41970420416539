import { createClassName } from "../../../helpers";

import "./PageLayout.scss";

const cn = createClassName("PageLayout");

const PageLayout = ({ children }) => (
  <div className={cn("root")}>{children}</div>
);

export default PageLayout;
